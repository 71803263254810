export default {
  table: {
		/**
     * Default pagination property
     */
		pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    }
	},
  
  rules: {
    /**
     * Define schedule rules
     */
    schedule: {
      schedule: ['schedule', {
        rules: [{
          required: true,
          message: 'Tanggal publikasi tidak boleh kosong!'
        }]
      }]
    }
  },
}